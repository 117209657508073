export enum Experiment {
  UseNewPrivacyModalContent = 'specs.myAccount.UseNewPrivacyModalContent',
  ShowBlockedMembersModalRedesign = 'specs.myAccount.showBlockedMembersModalRedesign',
  EnableCSSOptimization = 'specs.myAccount.EnableCSSOptimization',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorMyAccount',
  ChangeLoginInfo = 'specs.membersArea.ChangeLoginInfo',
  UseDynamicUnblockTranslation = 'specs.myAccount.UseDynamicUnblockTranslation',
  UseNewBlockedEmptyState = 'specs.profileCardOOI.useNewBlockedEmptyState',
  EnableSettingsResetButton = 'specs.myAccount.EnableDesignTabResetButtonPerPage',
  EnableNewSettings = 'specs.myAccount.UseNewSettings',
  ShowBlockedMembersModalEmptyState = 'specs.myAccount.ShowBlockedMembersModalEmptyState',
  ShowPrivacySettingsMessageForSiteOwners = 'specs.myAccount.ShowPrivacySettingsMessageForSiteOwners',
  EnableButtonTextSetting = 'specs.myAccount.ShowButtonTextSetting',
}
